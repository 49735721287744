import { OidcClient } from 'oidc-client-ts';
import { loadApiDetails } from './endpoints';

export class OIDC {
  static client: OidcClient;

  static async loadClient() {
    if (!OIDC.client) {
      const { API_URL, AUTH_PROVIDER } = await loadApiDetails();
      OIDC.client = new OidcClient({
        authority: AUTH_PROVIDER,
        client_id: 'developer_hub',
        redirect_uri: `${window.location.origin}/oidc/callback`,
        response_type: 'code',
        resource: API_URL,
        scope: 'scripts.read',
        post_logout_redirect_uri: `${window.location.origin}`,
      });
    }
  }

  static async signIn() {
    await OIDC.loadClient();
    const { url } = await OIDC.client.createSigninRequest({});
    return url;
  }

  static async signOut() {
    await OIDC.loadClient();
    const { url } = await OIDC.client.createSignoutRequest({});
    return url;
  }

  static async processSigninResponse(url: string) {
    await OIDC.loadClient();
    return await OIDC.client.processSigninResponse(url);
  }
}
