import React from 'react';

const ExternalLinkIcon = () => {
    return (
        <svg style={{ marginTop: '4px' }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
          d="M5.69998 13.3442L4.65578 12.3L9.2058 7.74995H5.25V6.25H11.75V12.75H10.25V8.79415L5.69998 13.3442ZM16 8.99998V1.99998H8.99998V0.5H17.5V8.99998H16ZM2.3077 17.5C1.80257 17.5 1.375 17.325 1.025 16.975C0.675 16.625 0.5 16.1974 0.5 15.6923V0.5H1.99997V15.6923C1.99997 15.7692 2.03202 15.8397 2.09612 15.9038C2.16024 15.9679 2.23077 16 2.3077 16H17.5V17.5H2.3077Z" 
          fill="#5D7599"/>
        </svg> 
    )
  }

export default ExternalLinkIcon;