import { OIDC, cookie } from '@devportal/common';

const login = async (redirectPath : string) => {
    window.localStorage.setItem('redirectPath', redirectPath);
    const url = await OIDC.signIn();
    window.location.assign(url);
}

const logout = async () => {
    cookie.remove('accessToken', { path: '/' });
    const url  = await OIDC.signOut();
    window.location.assign(url);
}

export { login, logout };